.amountContainer {
  display: flex;
  justify-content: flex-end;
}

.inputContainer {
  position: relative;

  input {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.amountInput {
  display: flex;
  align-items: center;

  &,
  input {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    color: var(--black-4);
    font-family: var(--font-family);
  }

  input {
    border: 0;
    padding: 0;
    outline: 0;
  }

  .placeholder {
    opacity: 0;
    user-select: none;
  }
}
