.checkmark {
  position: absolute;
  top: 0px;
  right: 15px;
  height: 24px;
  width: 24px;
  border: 2px solid var(--grey-1);
  border-radius: 50%;
}
.checkmark.blue {
  border: 2px solid var(--blue);
}
.checkmark.left {
  right: auto;
  left: 0;
}

.checked {
  background-color: var(--blue);
  border: none !important;
}

.line {
  display: none;
  width: 100%;
  height: 100%;
}

.checked .line {
  display: block;
}

.checkboxContainer {
  position: relative;
  min-height: 25px;
  display: flex;
  align-items: center;

}

.checkbox.checkboxLeft {
  margin-left: 40px;
}
.checkbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}
