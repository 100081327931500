.head {
  padding-bottom: 30px;
}
.home {
  padding: 0 12px;
}

.home .leaseInfo {
  padding: 0px 12px;
  margin-bottom: 24px;
}

.home .leaseDate {
  margin: 20px 0 20px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}
.home .leaseDate .date {
  font-weight: bold;
}

.bottomContainer {
  position: -webkit-sticky;
  position: sticky;
  top: calc(100% - 80px);
  margin: 0px auto 20px;
  padding: 0 35px;
}

.bottomBtn {
  width: 295px;
  height: 48px;
  border: 1px solid var(--black);
  border-radius: 24px;
  background-color: var(--black);
  color: var(--white);
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 20px;
}
.logout {
  background-color: var(--white);
  color: var(--black);
}

.balanceContainer {
  background-color: var(--white);
  position: relative;
  align-items: center;
  margin: 0;
  text-decoration: none;
  color: var(--black);
  border-radius: 15px;

  box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.1);
}
.balanceContainer .balanceElement {
  padding: 21px 23px;
}

.balanceContainer .tasks {
  background: var(--grey-10);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 15px 0;
}

.balanceContainer .tasks .task {
  position: relative;
  padding: 13px 23px;
  cursor: pointer;
}

.sectionTitle {
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: var(--grey-1);
}
.balanceContainer .amount {
  font-size: 35px;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 9px;
}
.balanceContainer .text {
  font-size: 13px;
}

.balanceContainer .scheduled {
  display: flex;
  margin-top: 7px;
  font-size: 12px;
  line-height: 20px;
  color: var(--green);
}
.balanceContainer .scheduled > svg {
  margin-right: 7px;
}

.iconCenter {
  position: absolute;
  right: 20px;
  top: calc(50% - 5px);
}

.additional {
  padding: 37px 12px 20px 12px;
}
.additional .link {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.2px;
  margin: 25px 0;
}

.breakdown {
  padding: 22px 23px;
}
.breakdown > span {
  font-size: 13px;
  line-height: 16px;
}
.breakdown > .items {
  margin-top: 31px;
}
.breakdown > .items > .item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.breakdown > .items > .item > .itemName {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: -0.2px;
}
.breakdown > .items > .item > .itemAmount {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}
