.container {
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  max-width: var(--max-container-width);
  width: 100%;
  top: 0;
  scroll-behavior: smooth;
  height: 100%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
}

.safeAreaContainer {
  padding-left: 26px;
  padding-right: 26px;
}

@supports (padding: max(0px)) {
  .safeAreaContainer {
    padding-left: max(26px, env(safe-area-inset-left));
    padding-right: max(26px, env(safe-area-inset-right));
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .container {
    -webkit-overflow-scrolling: touch; /* https://github.com/scottjehl/Device-Bugs/issues/8 */
  }
}
