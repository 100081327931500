.inputContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputContainer > label {
  font-size: 14px;
  color: var(--dark-grey);
}

.input {
  appearance: none;
  color: var(--black);
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid var(--grey-4);
  border-radius: 0;
  padding: 5px 30px 5px 10px;
  background: none;
  outline: none;
  transition: all 0.3s;
}
.inputContainer.hasIcon > .input {
  padding-left: 60px;
}
.inputContainer > .input:focus {
  border-color: var(--black);
}

.input::placeholder {
  font-size: 18px;
  color: var(--dark-grey);
  font-weight: 500;
}

.icon {
  position: absolute;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.prefix {
  position: absolute;
  top: 20px;
}
.prefix.disabled {
  opacity: 0.05;
}
