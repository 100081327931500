.progressBar {
  margin: 0;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: var(--grey-4);
  position: relative;
}
.progressBar .fill {
  transform-origin: left;
  height: 30px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--green);
  transition: transform 0.5s;
}

.progress {
  margin: 5px 0;
  font-size: 12px;
  letter-spacing: -0.2px;
  font-weight: 500;
  line-height: 16px;
  position: absolute;
  top: 3px;
  right: 15px;
}

