.main {
  flex: 1;
}

.form {
  height: 100%;
}

.inputContainer {
  padding-bottom: 30px;
}
.inputContainer input {
  padding: 0;
}
.inputContainer label {
  color: var(--dark-grey);
  font-size: 14px;
}

.recaptcha {
  margin: 10px 0;
}

.link {
  padding-top: 8px;
}
.link button {
  color: var(--blue);
  font-size: 15px;
  line-height: 20px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.error > input {
  border-bottom: 1px solid var(--red);
}
.errorMessage {
  color: var(--red);
  margin-top: 5px;
}
