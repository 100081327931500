.head {
  padding: 48px 24px;
}
.head.grey {
  background-color: var(--grey-5);
}
.head.black {
  background-color: var(--black);
}
.head.borderBottom {
  border-bottom: 1px solid var(--grey-4);
}

.header {
  word-break: break-word;
  padding: 0;
}

.subHeader {
  font-size: 16px;
  line-height: 21px;
  margin-top: 12px;
}
