.container {
  opacity: 1;
}
.item {
  position: relative;
  border-bottom: 1px solid var(--grey-4);
  padding: 15px 0;
}
.clickable {
  cursor: pointer;
}
.disabled .nameRow,
.disabled .description {
  opacity: 0.4;
  cursor: not-allowed;
}

.item .nameRow {
  display: flex;
}

.item .status {
  margin-left: 15px;
}

.item .name {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.39px;
}
.item .description {
  font-size: 13px;
  line-height: 20px;
  color: var(--grey-1);
  letter-spacing: -0.21px;
}
.item .remove {
  cursor: pointer;
  position: absolute;
  padding: 7px 15px;
  right: -15px;
  top: calc(50% - 15px);
}

.addBtn {
  margin-top: 15px;
}
.verifyBtn {
  margin-top: 5px;
}

.confirmTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.2px;
}
.confirmDescription {
  font-size: 13px;
  line-height: 16px;
}
