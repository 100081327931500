:root {
  --white: #ffffff;
  --black: #000000;
  --black-2: #333333;
  --black-3: #343434;
  --black-4: #1c1c1c;
  --grey-5: #f3f3f3;
  --grey-2: #f4f4f4;
  --grey-10: #fbfbfd;
  --grey-1: #c2bebb;
  --grey-4: #d8d7d6;
  --grey-7: #767676;
  --dark-grey: #a7a7a9;
  --red: #fe3a32;
  --green: #24db96;
  --blue: #0089ff;
  --orange: #ffcb37;

  --max-container-width: 375px;

  --font-family: "Helvetica Neue", "Arial", sans-serif;
}
