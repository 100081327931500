.footer {
  background-color: var(--grey-2);
  padding: 24px;
}

.logo {
  height: 16px;
  margin-top: 56px;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.link {
  display: inline-block;
  color: var(--black-4);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -0.18px;
  text-decoration: none;
  cursor: pointer;
}

.signOut {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 40px;
}
