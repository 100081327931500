.content {
  padding: 0;
}

.container {
  min-height: 100%;
}

.noItems {
  padding: 0px 26px;
}

.group {
  margin: 0;
}
.group .title {
  background: var(--grey-2);
  color: var(--grey-7);
  padding: 7px 26px;
  line-height: 22px;
  font-size: 15px;
}
.group .payments {
  display: block;
}
.group .payments .payment {
  border-bottom: 1px solid var(--grey-4);
  padding: 27px 26px 18px 26px;
  cursor: pointer;
}
.group .payments .payment:last-child {
  border-bottom: 0;
}
