.task {
  background-color: var(--white);
  position: relative;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: none;
  color: var(--black);
  border-radius: 15px;
  cursor: default;

  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    box-shadow: none;
    opacity: 0.4;
  }

  &.completed {
    background-color: var(--grey-2);
    box-shadow: none;
  }

  &.warning {
    .label p {
      color: var(--grey-1);
    }

    .icon {
      color: var(--orange);
    }
  }

  &.error {
    .label p {
      color: var(--grey-1);
    }

    .icon {
      color: var(--red);
    }
  }

  .label {
    position: relative;
    width: 100%;
    padding: 25px 70px 25px 25px;

    h3 {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      line-height: 29px;
      letter-spacing: -0.18px;

      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    p {
      margin: 10px 10px 0px 0;
    }

    .action {
      display: flex;
      font-size: 12px;
      font-weight: normal;
      line-height: 14px;
      color: var(--black);
    }
  }
}

.icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.roundIcon {
  width: 32px;
  height: 32px;
}

.status {
  margin-left: 6px;
}
