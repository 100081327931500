.row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}
.row .date {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.row .status {
  font-size: 14px;
}
.row .amount {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
}
.row .balance {
  font-size: 12px;
  color: lightgrey;
}
