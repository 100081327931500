.modal {
  height: 40vh;
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.title {
  margin: 25px 45px 0px 26px;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
  min-height: 45px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(40vh - 70px);
}

.description {
  margin: 25px 26px 0px;
  font-size: 13px;
  line-height: 18px;
}

.actions {
  display: flex;
  margin: 32px;
  width: calc(100% - 64px);
}

.button {
  color: var(--blue);
  height: 49px;
  width: 172.31px;
  border-radius: 30px;
  font-size: 17px;
  font-weight: initial;
  line-height: 20px;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}
.button.fullWidth {
  width: 250px;
}

.deleteButton {
  color: var(--red);
}

.errorMessage {
  padding: 0 26px;
  text-align: center;
  color: var(--red);
  margin-top: 5px;
}

.yes {
  border-radius: 24px;
  background-color: var(--black);
  color: var(--white);
}
