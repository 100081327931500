.title {
  font-size: 16px;
  line-height: 18px;
  color: var(--dark-grey);
}

.contactBlock {
  margin: 20px 0;
}
.contact {
  display: flex;
  align-items: center;
  margin: 20px 0;
  margin-bottom: 20px;
  margin-left: -5px;

  color: var(--blue);
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
}
.contact svg {
  height: 35px;
  width: 50px;
}
