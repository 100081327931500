.link {
  background: none;
  text-decoration: none;
  border: none;
  padding: 0 !important;
  color: var(--blue);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.21px;
  cursor: pointer;
  display: block;
  font-family: var(--font-family);
}
