.form {
  height: 100%;
}

.item {
  position: relative;
  padding: 18px 0 22px 0;
}

.input {
  font-size: 48px;
  line-height: 57px;
  font-weight: 500;
  letter-spacing: 0.28px;
  height: 65px;
  padding-left: 40px;
}
.input::placeholder {
  font-size: 48px;
  line-height: 57px;
  font-weight: 500;
  letter-spacing: 0.28px;
  color: var(--grey-2);
}
.inputPrefix {
  top: 5px;
}
.inputContainer {
  font-size: 48px;
  line-height: 57px;
  font-weight: 500;
  letter-spacing: 0.28px;
  height: 65px;
  margin-top: 4px;
}

.errorMessage {
  color: var(--red);
  margin-top: 10px;
}
