.container {
  margin: auto;
  max-width: var(--max-container-width);

  position: fixed;
  width: 100%;
  top: 0;
  background: var(--white);
  z-index: 9999;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  height: 50px;
}

.header {
  margin: 10px 0;
  padding: 0 26px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

@supports (padding: max(0px)) {
  .header {
    padding-left: max(26px, env(safe-area-inset-left));
    padding-right: max(26px, env(safe-area-inset-right));
  }
}
