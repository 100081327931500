.status {
  font-size: 11px;
  line-height: 15px;
  font-weight: 500;
  border-radius: 15px;
  padding: 3px 8px;
  color: var(--white);

  &.pending {
    background: var(--blue);
  }

  &.completed {
    background: var(--grey-1);
  }

  &.error {
    color: var(--black);
    background: var(--orange);
  }
}
