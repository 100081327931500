.home {
  padding: 4px 12px 30px;
  flex: 1;
}

.additional {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
}

.linkButton {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin: 0 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
