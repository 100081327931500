.container {
  min-height: 100%;
}
.content {
  padding: 0 26px;
}

.copy {
  padding-top: 28px;
}

.row {
  margin-top: 32px;
}
.row:last-child {
  margin-bottom: 32px;
}
.row .title {
  font-size: 16px;
  line-height: 18px;
  color: var(--dark-grey);
}
.row .text {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  margin-top: 7px;
}
