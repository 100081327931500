.container {
  background: var(--black);
  color: var(--white);
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: var(--max-container-width);
}

.content {
  margin: 0 30px;
}

.content h1 {
  margin-top: 50px;
}
.content .text {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
  margin-top: 20px;
}
