.content {
  padding: 0;
}

.container {
  min-height: 100%;
}

.section {
  padding: 29px 26px;
}
.section .columns {
  display: flex;
  justify-content: space-between;
}
.section .columns > div {
  min-width: 50%;
  margin-right: 10px;
}
.section.inner {
  padding-top: 0px;
}
.section .sectionTitle {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: var(--grey-1);
}
.section .sectionContent {
  margin-top: 8px;
}
.section .sectionContent.sameRow {
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.date {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.39px;
}
.amount {
  min-width: 50%;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  margin-right: 10px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.39px;
}
.subtitle {
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: -0.39px;
  margin-top: 7px;
}
.description {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.21px;
}

.cancelButton {
  border: 1px solid var(--red);
  background: var(--white);
  color: var(--red);
}
