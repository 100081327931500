.body {
  padding: 30px 20px 0 20px;
}

.text {
  font-size: 16px;
  margin-bottom: 35px;
}
.text > span {
  font-weight: bold;
}

.pay {
  min-height: 100%;
}

.paymentMethod {
  display: flex;
  justify-content: space-between;
  color: var(--black-4);
  font-size: 17px;
  font-weight: normal;
  line-height: 20px;
  margin: 50px 0 30px 0;
}
.paymentMethod div {
  display: flex;
  flex-direction: column;
}
.paymentMethod span:first-child {
  font-weight: bold;
  font-size: 22px;
}

.pm {
  margin-top: 10px;
  font-size: 16px;
  color: var(--dark-grey);
}

.setup {
  font-weight: bold;
  font-size: 18px;
}

.note {
  font-size: 12px;
  line-height: 16px;
  color: var(--grey-1);
  padding: 22px 24px;
}
