.confirm {
  min-height: 100%;
  padding: 44px 20px 0 20px;
  background-color: var(--blue);
  color: var(--white);
}

.confirm p {
  margin: 10px 0 30px 0;
  font-size: 18px;
  line-height: 21px;
}

.confirm p a {
  color: var(--white);
}

.btn {
  background-color: var(--white);
  color: var(--black);
}
.btnContainer {
  padding: 0 10px;
}

.icon {
  width: 66px;
  height: 66px;
  margin-bottom: 32px;
}
