.actions {
  position: -webkit-sticky;
  position: sticky;
  top: calc(100% - 80px);
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
}

.btn {
  width: 295px;
  height: 48px;
  margin: auto;
  border-radius: 24px;
  background-color: var(--black);
  color: var(--white);
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}
.btn.small {
  width: 200px;
}
