.paymentError {
  cursor: pointer;
  background: var(--black-3);
  color: var(--white);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 23px 0 23px 23px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.paymentError > div {
  margin-left: 10px;
}
.paymentError .paymentErrorTitle {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 7px;
}
.paymentError .paymentErrorText {
  font-size: 13px;
  color: var(--grey-1);
}

