.item:first-child {
  border-top: 1px solid var(--grey-4);
}
.item {
  position: relative;
  border-bottom: 1px solid var(--grey-4);
  padding: 20px 24px;
}

.item .row {
  display: flex;
  justify-content: space-between;
}

.item .name {
  font-size: 18px;
  line-height: 21px;
}
.item .content {
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
}
.item .description {
  font-size: 13px;
  line-height: 20px;
  color: var(--grey-1);
}
.item .icon {
  position: absolute;
  right: 26px;
  top: calc(50%);
  transform: translateY(-50%);
}

.item.clickable {
  cursor: pointer;
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-1);
  margin-top: 14px;
}
