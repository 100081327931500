@import-normalize;

@font-face {
  font-family: "Helvetica Neue";
  font-weight: 300;
  src: local("Helvetica Neue-Light"), local("HelveticaNeue-Light");
}
@font-face {
  font-family: "Helvetica Neue";
  font-weight: 400;
  src: local("Helvetica Neue"), local("HelveticaNeue");
}
@font-face {
  font-family: "Helvetica Neue";
  font-weight: 500;
  src: local("Helvetica Neue-Medium"), local("HelveticaNeue-Medium");
}
@font-face {
  font-family: "Helvetica Neue";
  font-weight: 700;
  src: local("Helvetica Neue-Bold"), local("HelveticaNeue-Bold");
}

html,
body {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body * {
  box-sizing: border-box;
}

* {
  outline: none;
}

h1 {
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: -0.67px;
  margin: 0;
  padding: 20px 0;
}

h2 {
  font-size: 23px;
  font-weight: 500;
}

h3 {
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: -0.18px;
}

hr {
  border: 0;
  border-top: 1px solid var(--grey-4);
  color: transparent;
  margin: 0;
}

a {
  color: var(--black);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.green {
  color: var(--green);
}
