.container {
  position: relative;
  z-index: 10000;

  animation: fadeIn 0.2s;
  -webkit-animation: fadeIn 0.2s;
  -moz-animation: fadeIn 0.2s;
  -o-animation: fadeIn 0.2s;
  -ms-animation: fadeIn 0.2s;
}

.overlay {
  z-index: 10000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: var(--black);
  opacity: 0.75;
}

.modal {
  z-index: 10100;

  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-height: 189px;
  max-height: 600px;
  background: var(--white);
  border-radius: 15px;
  width: 305px;
}

.content {
  padding: 30px 30px 68px 30px;
  min-height: 189px;
  max-height: 600px;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}

.content .title {
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
}

.content .body {
  text-align: center;
  font-size: 15px;
  line-height: 20px;
}

.buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--white);
  border-top: 1px solid var(--grey-4);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.buttons button {
  cursor: pointer;
  height: 48px;
  width: 100%;
  font-size: 17px;
  line-height: 21px;
  font-weight: 500;

  background: none !important;
  text-decoration: none;
  border: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
