.pay {
  padding: 0 26px;
}

.body {
  padding-bottom: 40px;
}

.line {
  border-top: 1px solid var(--grey-4);
}

.error {
  color: var(--red);
}
