.content {
  padding: 0;
}

.pay {
  min-height: 100%;
}

.note {
  font-size: 12px;
  color: var(--grey-1);
  padding: 22px 26px;
}

.bold {
  font-weight: bold;
}

.setup {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.2px;
}

.buttonsContainer {
  position: -webkit-sticky;
  position: sticky;
  top: calc(100% - 80px);
  margin: 0px auto 20px;
  padding: 0 35px;
}

.save {
  width: 214px;
  height: 48px;
  border: 1px solid var(--black);
  border-radius: 24px;
  background-color: var(--black);
  color: var(--white);
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  margin: auto;
  margin-bottom: 20px;
}

.cancel {
  width: 200px;
  height: 48px;
  color: var(--red);
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  margin: auto;
  margin-bottom: 20px;
}
